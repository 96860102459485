import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import DropDownBtn from '../components/DropDownBtn'

export default function IndexSlider({ edges, productsIndex, totalCount }) {
    const [marginLeftState, setMarginLeftState] = useState(0)
    const [dropDownBtnState, setDropDownBtnState] = useState(true)
    const productsList = edges
    const sliderItemWidth = 300
    const maxSliderWidth = - (sliderItemWidth * totalCount)
    const categoryName = productsList[0].node.frontmatter.category
    const container = useRef(null)
    const touchStart = useRef(0)

    useEffect(() => {
        container.current.style.width = maxSliderWidth + 'px';
    }, [])

    useEffect(() => {
        container.current.style.marginLeft = marginLeftState + "px"
    }, [marginLeftState])

    useEffect(() => {
        if(window.innerWidth < 720) {
            container.current.style.height = dropDownBtnState ? 0 : '430px'
        }
    }, [dropDownBtnState])

    // Mouse events
    const handleMouseDown = event => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = event => {
        let currentMarginLeft = container.current.style.marginLeft === "" ? 0 : parseInt(container.current.style.marginLeft)
    };

    const handleMouseUp = () => {
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('mousemove', handleMouseMove);
    };

    // Touch events
    const handleTouchStart = event => {
        touchStart.current = event.touches[0].clientX
        container.current.style.transition = "none";
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);
    };

    const handleTouchMove = event => {
        let currentMarginLeft = container.current.style.marginLeft === "" ? 0 : parseInt(container.current.style.marginLeft)
        let newMarginLeft = Math.trunc(currentMarginLeft - touchStart.current + event.touches[0].clientX)
        touchStart.current = event.touches[0].clientX
        container.current.style.marginLeft = newMarginLeft >= 0 ? 0 : newMarginLeft + "px"
    };

    const handleTouchEnd = () => {
        touchStart.current = 0
        container.current.style.transition = "margin-left 0.5s";
        let newMarginLeft = Math.round(parseInt(container.current.style.marginLeft) / sliderItemWidth) * sliderItemWidth
        container.current.style.marginLeft = newMarginLeft + 'px';

        document.removeEventListener('touchend', handleTouchEnd);
        document.removeEventListener('touchmove', handleTouchMove);
    };

    // Click events
    const moveSliderLeft = () => {
        setMarginLeftState(marginLeftState >= 0 ? 0 : marginLeftState + sliderItemWidth)
    }

    const moveSliderRight = () => {
        let newMarginLeftMargin = marginLeftState - sliderItemWidth
        let coef = Math.round(newMarginLeftMargin / sliderItemWidth)
        setMarginLeftState(newMarginLeftMargin <= maxSliderWidth ? marginLeftState : sliderItemWidth * coef)
    }

    return (
        <section className="slider-products">
            <div className="slider-btn"><button className="icon-btn icon-btn_left" onClick={moveSliderLeft} aria-label="Left"></button></div>
            <div className="slider">
                <div className="drop-down-section">
                    <h3 className="slider-category">{categoryName} </h3>
                    <DropDownBtn actively={dropDownBtnState} onClick={() => setDropDownBtnState(!dropDownBtnState)} />
                </div>
                <div ref={container} className={`slide-container ${"slide-container-" + productsIndex}`}>
                    {productsList.map(product => (
                        <div className="slider-item" key={product.node.id} onMouseDown={handleMouseDown} onTouchStart={handleTouchStart} role="presentation">
                            <Link to={"/products/" + product.node.frontmatter.slug} >
                                <img src={'/img/products/' + product.node.frontmatter.slug + '/preview.jpg'} alt="preview" />
                                <p>{product.node.frontmatter.title}</p>
                            </Link>
                        </div>
                    ))}
                </div>

            </div>
            <div className="slider-btn"><button className="icon-btn icon-btn_right" onClick={moveSliderRight} aria-label="Right"></button></div>
        </section>

    )
}
