import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import Slider from "../components/Slider"

export default function IndexPage ({ data }) {
  const productsList = data.allMarkdownRemark.group

  return (
    <Layout>
      <PageTitle text="Наша продукция" />
        {productsList.map((products, index) => (
          <Slider {...products} productsIndex={index} key={index}/>
        ))}
    </Layout>
  )
}

// export page query
export const query = graphql`
query IndexPage {
  allMarkdownRemark(sort: {fields: frontmatter___priority}) {
    group(field: frontmatter___category_priority) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            slug
            category
          }
        }
      }
    }
  }
}
`